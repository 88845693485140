@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

h1 {
    font-family: 'Open Sans', sans-serif;
    color: white;
    text-shadow: 2px 2px 30px rgb(73, 73, 73);
}

p {
    font-family: 'Open Sans', sans-serif;
    color: white;
    text-shadow: 2px 2px 10px rgb(73, 73, 73);
}

.attacks {
    font-family: 'Open Sans', sans-serif;
    color: white;
}

.stat-card {
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.stat-card h2 {
    font-size: 20px;
    text-shadow: 2px 2px 30px black;
}

.stat-card table {
    width: 100%;
    border-collapse: collapse;
}

.stat-card table th, .stat-card table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}