.dashboard {
    font-family: 'Open Sans', sans-serif;
    color: white;
}

.stat-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.stat-card {
    width: calc(50% - 10px);
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.stat-card h2 {
    font-size: 20px;
    text-shadow: 2px 2px 30px black;
}

.time-range {
    cursor: pointer;
    text-decoration: underline;
}

.time-range:hover {
    color: lightblue;
}