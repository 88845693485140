.container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .inner-container {
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    backdrop-filter: blur(100px);
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.5);
    display: flex;  /* added */
    overflow: hidden; /* added */
  }
  
  .sidebar {
    width: 20%;  /* one-fifth of the parent's width */
    height: 100%;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(100px);
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  
  .logo {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  
  .tab-icon {
    height: 50px; /* changed from width to height */
    display: block;
    margin-left: 40px; /* changed from margin: 20px auto */
    margin-bottom: 20px;
    cursor: pointer; /* added */
  }
  
  .content {
    flex: 1; /* This will make it take up the rest of the space */
    padding: 20px; /* Add some space around the content */
}