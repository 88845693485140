@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.attacks h1 {
    font-family: 'Open Sans', sans-serif;
    color: white;
    text-shadow: 2px 2px 30px rgb(73, 73, 73);
}

.attacks p {
    font-family: 'Open Sans', sans-serif;
    color: white;
    text-shadow: 2px 2px 10px rgb(73, 73, 73);
}

td, th {
    color: white;
    font-family: 'Open Sans', sans-serif;
}